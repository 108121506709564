export const useAb25021 = () => {
  const abTestStore = useAbTestStore()
  const { sendAbShowEvent, sendAbTestEvent } = abTestStore
  const { tests } = storeToRefs(abTestStore)
  const { isDesktop } = useDevice()

  const ab25021 = ref()

  const ab25021V0 = computed(() => ab25021.value !== 'v1' && isDesktop)
  const ab25021V1 = computed(() => ab25021.value === 'v1' && isDesktop)

  onMounted(() => {
    ab25021.value = tests?.value?.get(25021)
  })

  return {
    tests,
    ab25021V0,
    ab25021V1,
    sendAbShowEvent,
    sendAbTestEvent,
  }
}
